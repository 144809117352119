import axios from '@axios'

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorGreater = (val, ...val2) => val >= val2

export const validatorDoubleCpf = async (val, args) => {
  if ((val.length === 14 || val.length === 18)) {
    try {
      const cpf = val.replace(/\D/g, '')
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/user/getByCpf/${cpf}/${args}`)
      if (response.data.id) return false
    } catch (error) {
      // 404
      return true
    }
  }
  return true
}

export const validatorDoubleProviderIdentification = async (val, args) => {
  if ((val.length === 14 || val.length === 18)) {
    try {
      const identification = val.replace(/\D/g, '')
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/provider/getByIdentification/${identification}/${args}`)
      if (response.data.id) return false
    } catch (error) {
      // 404
      return true
    }
  }
  return true
}

/**
 * BirthDate não pode ser maior que hj
 * BirthDate não pode ser menor que 200 anos antes de hj
 * @param {*} val
 * @returns boolean
 */
export const validatorBirthDateValidator = val => {
  const today = new Date()
  today.setUTCHours(23, 59, 59, 998)

  const dateToCompare = new Date(val)
  dateToCompare.setUTCHours(23, 59, 59, 998)

  const limitDate = -2208902400002 // GMT: Monday, 1 January 1900 23:59:59.998

  return (dateToCompare.getTime() < today.getTime()) && (dateToCompare.getTime() >= limitDate)
}

export const validatorCpfCnpjValidator = val => {
  if (!val || val.length === 0) return true
  if (val.length === 14) {
    let cpf = val.trim()
    cpf = cpf.replace(/\D/g, '')
    cpf = cpf.split('')

    let v1 = 0
    let v2 = 0
    let aux = false

    for (let i = 1; cpf.length > i; i += 1) {
      if (cpf[i - 1] !== cpf[i]) aux = true
    }

    if (aux === false) return false

    for (let i = 0, p = 10; (cpf.length - 2) > i; i += 1, p -= 1) {
      v1 += cpf[i] * p
    }

    v1 = ((v1 * 10) % 11)
    if (v1 === 10) v1 = 0

    if (v1 !== Number(cpf[9])) return false

    for (let i = 0, p = 11; (cpf.length - 1) > i; i += 1, p -= 1) {
      v2 += cpf[i] * p
    }

    v2 = ((v2 * 10) % 11)

    if (v2 === 10) v2 = 0

    if (v2 !== Number(cpf[10])) return false
    return true
  }
  if (val.length === 18) {
    let cnpj = val.trim()

    cnpj = cnpj.replace(/\./g, '')
    cnpj = cnpj.replace('-', '')
    cnpj = cnpj.replace('/', '')
    cnpj = cnpj.split('')

    let v1 = 0
    let v2 = 0
    let aux = false

    for (let i = 1; cnpj.length > i; i += 1) {
      if (cnpj[i - 1] !== cnpj[i]) aux = true
    }

    if (aux === false) return false

    for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i += 1, p1 -= 1, p2 -= 1) {
      if (p1 >= 2) v1 += cnpj[i] * p1
      else v1 += cnpj[i] * p2
    }

    v1 %= 11

    if (v1 < 2) v1 = 0
    else v1 = (11 - v1)

    if (v1 !== Number(cnpj[12])) return false

    for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i += 1, p1 -= 1, p2 -= 1) {
      if (p1 >= 2) v2 += cnpj[i] * p1
      else v2 += cnpj[i] * p2
    }

    v2 %= 11

    if (v2 < 2) v2 = 0
    else v2 = (11 - v2)

    if (v2 !== Number(cnpj[13])) return false
    return true
  }
  return false
}

export const validatorResponsibleRequired = val => {
  const today = new Date()
  const birthDate = new Date(val)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1
  }
  return (age >= 18)
}
